import React from "react"
import Container from "../container"
import SocialIcons from "../social-icons"
import Title from "../title"
import styles from "./footer.module.scss"
import FooterLink from "./link"
import Img from "gatsby-image"
import { StaticQuery } from "gatsby"

export default function Footer() {
  return (
    <>
      <footer className={styles.footer}>
        <Container className={styles.footerContainer}>
          <div className={styles.col}>
            <Title headingLevel="h5">AFFA GROUP</Title>
            <p>
              Kamu kurumlarında görev yapan şirket yöneticilerimiz, 17 ağustos
              marmara depreminde kriz yönetim merkezinde resmi görevler
              üstlenmiş, deprem arama kurtarma ve tüpraş yangınında etkin
              görevlerde bulunmuş normalleşme çalışmalarında aktif rol
              oynamışlardır. 2002 yılında faaliyete başlayan şirket
              yöneticilerimiz 2006 yılından itibaren şirketi kurmak için adım
              atmışlardır.
            </p>
          </div>

          <div className={styles.col}>
            <Title headingLevel="h5">KURUMSAL</Title>
            <ul>
              <FooterLink link="/hakkimizda">Hakkımızda</FooterLink>
              <FooterLink link="/kalite-politikamiz">
                Kalite Politikamız
              </FooterLink>
              <FooterLink link="/belgelerimiz">Belgelerimiz</FooterLink>
              <FooterLink link="/kariyer">Kariyer</FooterLink>
              <FooterLink link="/kvkk-ve-gizlilik-politikasi">
                KVKK ve Gizlilik politikası
              </FooterLink>
            </ul>
          </div>

          <div className={styles.col}>
            <Title headingLevel="h5">HİZMETLERİMİZ</Title>
            <ul>
              <FooterLink link="/ortak-saglik-ve-guvenlik-birimi">
                Ortak Sağlık ve Güvenlik Birimi
              </FooterLink>
              <FooterLink link="/laboratuvar">Laboratuvar</FooterLink>
              <FooterLink link="/belgelendirme">Belgelendirme</FooterLink>
              <FooterLink link="/egitimlerimiz">Eğitimlerimiz</FooterLink>
            </ul>
          </div>

          <div className={styles.col}>
            <Title headingLevel="h5">İLETİŞİM BİLGİLERİ</Title>
            <p>
              AFFA İstanbul İş Güvenliği Laboratuvar Belgelendirme Eğitim ve
              Mühendislik A.Ş.
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <a
                  href="https://www.google.com/maps?ll=41.004966,28.665925&z=16&t=m&hl=en&gl=GB&mapclient=embed&q=63.+Sk.+No:20+D:33+Yakuplu+34524+Beylikd%C3%BCz%C3%BC/%C4%B0stanbul+T%C3%BCrkiye"
                  target="_blank"
                >
                  Yakuplu Mah. 63. Sokak No: 20A/33, Beylikdüzü/İstanbul,
                  Türkiye
                </a>

                <StaticQuery
                  query={query}
                  render={data => (
                    <Img
                      style={{
                        width: "80px",
                        height: "80px",
                        flex: "none",
                        alignSelf: "flex-start",
                      }}
                      fixed={data.adresQR.sharp.fixed}
                      alt="Adres QR"
                    />
                  )}
                />
              </div>
              <br />
              Tel: <a href="tel:+902128711060">+90 212 871 1060</a>
              <br />
              Tel: <a href="tel:+908504955606">+90 850 495 5606</a>
              <br />
              Email:{" "}
              <a href="mailto:info@affakalite.com.tr">info@affakalite.com.tr</a>
            </p>
          </div>
        </Container>
      </footer>
      <div className={styles.copyright}>
        <Container className={styles.copyrightContainer}>
          <span>
            Copyright {new Date().getFullYear()} affakalite.com.tr | Tüm hakları
            saklıdır.
          </span>
          <SocialIcons />
        </Container>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    adresQR: file(relativePath: { eq: "adres-qr.jpg" }) {
      sharp: childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
